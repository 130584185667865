<template>
    <el-scrollbar class="padding10" height='100%' >
        <div class="n-tabs">
            <span  class="n-btn" :class="{'active':itemType=='1'}" @click="swithChangeFun('1')">指标单选</span>
            <span class="n-btn" :class="{'active':itemType=='2'}" @click="swithChangeFun('2')">指标多选</span>
            <el-button type="primary" style="position: absolute;bottom: 3px;right: 10px;" size="small" @click="submitFun('submit')">提交</el-button>
            <el-button type="primary" style="position: absolute;bottom: 3px;right: 70px;" size="small" @click="submitFun('preview')">预览</el-button>
        </div>
        <br>
        <el-row  style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>地区</span>
                </div>
            </el-col>
            <el-col :span="21">
                <span v-if='regionLevel==0' style='color:#3F94FD;'>全国</span>
                <el-cascader v-else ref="myCascader" popper-class="cus-cascader-popper"  :options="regionList" :props="{'checkStrictly': true}" clearable @change='regionChangeFun' />
            </el-col>
        </el-row>
        <el-row  v-if='itemType==2'  style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10"><span>指标周期</span></div>
            </el-col>
            <el-col :span="21">
                <el-radio-group v-model="itemCycle"  class="cus-radio-g" >
                    <el-radio v-for='item in itemCycleList' :key="item.id" :label="item.id" size="large" @change='itemCycleChangeFun'>{{item.name}}</el-radio>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-row  style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>指标项</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-tag v-for='t in items' :key='t.id' closable class="tag-cus"   @close="delItem(t.id)">
                        {{t.itemName}}
                    </el-tag>
                    <i v-if='(!isShowItem && itemType=="1" && items.length<1)||(!isShowItem && itemType=="2")' class="iconfont ec-icon-circle-plus-outline txt-color" style="font-size: 20px;cursor: pointer;vertical-align: middle;"  @click="showItemFun"></i>
                </div>
            </el-col>
        </el-row>
        <div v-if='isShowItem'>
            <el-empty v-if='userFavoritesList.length<1' description="收藏夹还没有指标项快去添加吧~" />
            <div v-else  class="fav  clearfix">
                <i class="iconfont ec-icon-circle-close c-close" @click='isShowItem=false'></i>
                <el-scrollbar class="fav-left" >
                    <ul>
                        <li v-for='item in userFavoritesList' :key="item.id" :class="{'cur':item.id==favId}" @click.stop='getFavListFun(item.id)'>
                            <span>{{item.name}}</span>
                        </li>
                    </ul>
                </el-scrollbar>
                <el-scrollbar class="fav-right" >
                    <span class="item-tag " v-for='item in favList' :key='item.id' :class="{'is-disabled':item.isDisabled,'is-sel':items.filter(t=>t.itemId==item.itemId && t.objId==item.objId).length>0}" @click.stop="selItemFun(item)">{{item.itemName}}</span>
                </el-scrollbar>
            </div>
        </div>
        
        <el-row  style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>呈现方式</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-radio-group v-model="showMode" class="cus-radio-g" @change="radioChange">
                    <el-radio label="chart_line" size="large">趋势(折线图)</el-radio>
                    <el-radio label="chart_bar" size="large">趋势(柱状图)</el-radio>
                    <el-radio v-if='itemType==2' label="chart_pie" size="large">占比(饼图)</el-radio>
                    <el-radio v-if='itemType==1 && regionLevel>0' label="list_sort" size="large">排名</el-radio>
                    </el-radio-group>
                </div>
            </el-col>
        </el-row>
        <el-row   v-if='showMode=="list_sort"' style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>排名</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-radio-group v-model="rule"  class="cus-radio-g">
                    <el-radio label="grade" size="large">同级</el-radio>
                    <el-radio v-if='regionLevel>1' label="province" size="large">本省</el-radio>
                    <el-radio v-if='regionLevel>2' label="city" size="large">本市</el-radio>
                    </el-radio-group>
                </div>
            </el-col>
        </el-row>
        <el-row   v-if='itemType=="2" && ( showMode=="chart_line" || showMode=="chart_bar")' style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>纵轴</span>
                </div>
            </el-col>
            <el-col :span="21">
                <el-radio-group v-model="yAxisType"  class="cus-radio-g" >
                    <el-radio label="single" size="large">单轴</el-radio>
                    <el-radio label="multiple" :disabled='yAxisList.length==1' size="large">多轴</el-radio>
                </el-radio-group>
                <div v-if="yAxisType=='multiple'">
                    <span v-for='(item,index) in yAxisList' :key="item.key" class='span-nowrap'>
                        轴{{index+1}}:{{item.key}}
                    </span>
                </div>
            </el-col>
        </el-row>
        <el-row   v-if='showMode=="list_sort" || showMode=="chart_pie"' style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>日期</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-checkbox v-model="isCurDate" disabled>应用数据最新日期</el-checkbox>
                </div>
            </el-col>
        </el-row>
        <el-row  style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10"><span>标题</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-input v-model="title" maxlength="20" placeholder="标题名称" show-word-limit type="text"/>
                </div>
            </el-col>
        </el-row>
        <el-row  style="line-height:40px;padding-top: 10px;">
            <el-col :span="3" >
                <div class="txt-r pr-10">
                    <span>描述</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-input v-model="itemDesc"
                        maxlength="200"
                        placeholder="请输入描述"
                        show-word-limit
                        type="textarea"/>
                </div>
            </el-col>
        </el-row>
        <div v-if='isShowPreview' style="margin-top:10px;" >
            <el-divider class='cus-divider'>
                <span >预览</span>
            </el-divider>
             <span>{{chartDate?"日期："+chartDate:''}}</span>
            <div  style="padding-top:10px;height:350px;">
                <div v-if='showMode=="list_sort"' style="width:100%;height:100%;">
                    <el-table :data="sortDtInfo" stripe height="100%" style="width: 100%;">
                        <el-table-column type="index" label="排名" width="80" />
                        <el-table-column prop="regionName" label="地区"  />
                        <el-table-column prop="itemValue" label="值"  />
                    </el-table>
                </div>
                <div v-if='showMode!="list_sort"' id='chart' style="width:100%;height:100%;"></div>
            </div>
        </div>
    </el-scrollbar>
</template>
<script>
import { ref } from '@vue/reactivity';
import {getUserFavorites,getFavList,getAllRegionList,addAreaEcoSelf,addUserLog} from '@/http/basicsApi.js'
import {getRenewalCycleList,getRegionItemSort,getRegionItemLine,getRegionItemPie} from '@/http/ecoApi.js'
import { ElMessage  } from 'element-plus'
import * as echarts from 'echarts'
import {parseTimeOfCycle,getList} from '@/utils'
import { useRoute } from 'vue-router';
export default {
    props: {
         themeId:{
            type:Number
         },
         regionLevel:{
             type:Number,
             default:-1
         }
    },
    emits: ['refreshFun'],
    setup(props,{ emit }) {
        const route=useRoute();
        let currentUser=ref(JSON.parse(localStorage.getItem('currentUser')));//当前用户信息
        //区域经济的基本信息参数
        let itemType=ref(1);//指标类型，1：单选，2：多选
        let isShowItem=ref(false);//是否显示指标项列表
        let items=ref([]);//选中的指标项
        let regionCode=ref(props.regionLevel==0?'0':'');//地区编号
        let showMode=ref('');//呈现方式
        let rule=ref('');//排名规则，默认同级
        let title=ref('');//标题
        let itemDesc=ref('');//描述
        const myCascader = ref(null);
        let itemCycleList=ref([]);//
        let itemCycle=ref('');//指标周期
        let yAxisType=ref('single');//y轴显示单轴还是多轴，多轴以单位不同而分为多轴
        let isCurDate=ref(true);//是否应用最新日期
        let isShowPreview=ref(false);//是否显示预览
        ///区域经济编辑
        //1、指标类型的改变，单选、多选
        const swithChangeFun=(val)=>{
            console.log(val);
            itemType.value=val;
            //指标项、地区清空、呈现方式、排序规则
            itemCycle.value='';
            isShowItem.value=false;
            items.value=[];
            if(myCascader.value){
                myCascader.value.handleClear({});
            }
            regionCode.value=props.regionLevel==0?'0':'';
            showMode.value="";
            rule.value=''; 
            isShowPreview.value=false;
        }
        //地区的改变
        const regionChangeFun=(val)=>{
            console.log(val);
            isShowPreview.value=false;
            if(val){
                let index=val.length;
                let ary=regionList.value;
                for(let i=0;i<index;i++){
                    if(i<index-1){
                        ary=ary.find(t=>t.value==val[i]).children;
                    }else{
                        let regionInfo=ary.find(t=>t.value==val[i]);
                        console.log(regionInfo);
                        regionCode.value=regionInfo.value;
                    }
                }
            }
            else{
                regionCode.value='';
            }
        }
        // 指标周期的选择
        const itemCycleChangeFun=(val)=>{
            console.log(val);
            items.value=[];
            isShowItem.value=false;
            //showMode.value='';//呈现方式
            rule.value='';
            isShowPreview.value=false;
        }
        //2、显示指标项列表
        const showItemFun=()=>{
            isShowItem.value=true;
            //重置收藏夹为第一个
            getFavListFun(userFavoritesList.value[0].id)
        }
        //3、选择指标项
        let yAxisList=ref([]);//y轴列表
        const selItemFun=(item)=>{
            isShowPreview.value=false;
            if(itemType.value==1 && item.isDisabled==false){//单选
                items.value.push(item);
                isShowItem.value=!isShowItem.value
                yAxisList.value=[];
            }else if(itemType.value==2){
                //指标数量限制为50个
                if(items.value.length>=50){
                    ElMessage.warning({
                    message: "指标数量已达到限量" 
                    }); 
                    return false;
                }
                let filterAry= items.value.filter(t=>t.itemId==item.itemId && t.objId==item.objId);
                if(item.isDisabled==false && filterAry.length<1){
                    items.value.push(item)
                }
                items.value=items.value.map((t,index)=>{
                    return { ...t,id:index+1}
                });
                //根据选中的指标项进行分组，计算是否多个y轴
                yAxisList.value= getList(items.value,'itemUnit');
                //如果只有一个单位就是单轴
                if(yAxisList.value.length<=1){
                    yAxisType.value="single";
                }
            }
        }
        //删除选择的指标项
        const delItem=(id)=>{
            let index=items.value.map(t=>t.id).indexOf(id);
            items.value.splice(index,1);
            isShowPreview.value=false;
            //根据选中的指标项进行分组，计算是否多个y轴
            yAxisList.value= getList(items.value,'itemUnit');
            //如果只有一个单位就是单轴
            if(yAxisList.value.length<=1){
                yAxisType.value="single";
            }
        }
        
        //呈现方式、排序的单选的改变
        const radioChange=()=>{
            isShowPreview.value=false;
        }
        //获取收藏夹列表
        let userFavoritesList=ref([]);//收藏夹列表
        let favId=ref('');//选中的收藏夹
        const getUserFavoritesFun=async()=>{
            console.log(currentUser.value);
            const result=await getUserFavorites(currentUser.value.userId);
            //console.log(result);
            if(result.code==20000){
                userFavoritesList.value=result.data.filter(t=>t.itemNum>0);
            }
        }
        //获取收藏夹的指标项列表
        let favList=ref([]);//指标项列表
        const getFavListFun=async(id)=>{
            favId.value=id;
            let param={
                favId:parseInt( id),
                pageSize:1,
                pageIndex:1
            }
            const result=await getFavList(param);
            //console.log(result);
            if(result.code==20000){
                favList.value=result.data.dataList
                favList.value.forEach(t=>{
                    let gradeAry=[...t.itemGrade];
                    if(itemType.value==1 && gradeAry[props.regionLevel+1]=="1"){
                        t.isDisabled=false
                    }
                    else if(itemType.value==2 && gradeAry[props.regionLevel+1]=="1" && itemCycle.value==t.itemCycle){
                        t.isDisabled=false
                    }
                    else{
                        t.isDisabled=true;
                    }
                })
            }
        }
        //4、获取地区列表
        let regionList=ref([]);//地区列表
        const getAllRegionListFun=async()=>{
            const result=await getAllRegionList();
            console.log(result);
            if(result.code==20000){
                regionList.value=result.data;
                regionList.value.forEach(t=>{
                    if(t.level==props.regionLevel){
                        t.disabled=false
                    }
                    else{
                        t.disabled=true
                    }
                    if(t.children){
                        t.children.forEach(c=>{
                        if(c.level==props.regionLevel){
                                c.disabled=false
                            }
                            else{
                                c.disabled=true
                            } 
                            c.children.forEach(c1=>{
                                if(c1.level==props.regionLevel){
                                    c1.disabled=false
                                }
                                else{
                                    c1.disabled=true
                                } 
                            })
                        })
                    }
                })
            }
        }
        //获取指标周期
        const getItemCycleList=async()=>{
            const result=await getRenewalCycleList();
            if(result.code==20000){
                console.log(result);
                itemCycleList.value=result.data;
            }
        }
        //获取指标周期
        let sortDtInfo=ref([]);//排序列表
        let myChart=null;//图表实例
        let chartDate=ref('');//图表时间
        //5、提交，添加区域经济部件
        const submitFun=async(type)=>{
            console.log(rule.value);
            console.log('sss');
            let msg='';
            if(props.themeId<1){
                msg="无主题"
            }
            else if(items.value.length<1){
                msg='无指标项'
            }
            else if(itemType.value==2 && items.value.length<2){
                msg="指标项不能少于一个"
            }
            else if(!regionCode.value){
                msg='无地区'
            }
            else if(!showMode.value){
                msg="无呈现方式"
            }
            else if(showMode.value=='list_sort' && !rule.value){
                msg="无排名规则"
            }
            else if(!title.value && type=='submit'){
                msg="无标题"
            }
            //多轴不能多余3个
            else if((showMode.value=='chart_line' || showMode.value=='chart_bar') && yAxisType.value=="multiple" && yAxisList.value.length>3){
                msg="纵轴不能多余3个"
            }
            if(msg){
                ElMessage.warning({
                    message: msg 
                }); 
                return false;
            }
            if(showMode.value=='list_sort' && !rule.value){
                rule.value=JSON.stringify({sort:rule.value});
            }
            else if((showMode.value=='chart_line' || showMode.value=='chart_bar') && yAxisType.value=='multiple'){
                let yAxisItems=yAxisList.value.map(t=>{
                     return {item:t.item.join(',')} 
                    });
                rule.value=JSON.stringify({yAxisNum:yAxisList.value.length,item:yAxisItems.map(t=>t.item)})
            }
            console.log(items.value);
            let param={
                themeId:props.themeId,
                itemType:parseInt( itemType.value),
                items:JSON.stringify( items.value.map(t=>{
                    return {
                        id:t.id,
                        itemId:t.itemId,
                        objId:t.objId
                    }
                })),
                regionCode:regionCode.value,
                showMode:showMode.value,
                rule:rule.value,
                //date:'2022-01-01',
                title:title.value,
                desc:itemDesc.value
            }
            console.log(param);
            if(type=='preview'){//预览
                isShowPreview.value=true;
                chartDate.value='';
                if(showMode.value=="list_sort"){//排名
                    console.log(items.value);
                    let p={
                        regionCode:regionCode.value,
                        regionRange:rule.value,
                        itemId:items.value[0].itemId,
                        ObjId:items.value[0].objId
                    }
                    console.log(p);
                    //获取指标项信息
                    getRegionItemSort(p).then(res=>{
                        console.log(res);
                        if(res.code==20000){
                            sortDtInfo.value=res.data.dataList;
                            chartDate.value=res.data.date;
                        }
                    });
                }
                else if(showMode.value=="chart_line" || showMode.value=="chart_bar"){//趋势，折线图
                    let p={
                        regionCode:regionCode.value,
                        itemId:JSON.stringify(items.value)
                    }
                    getRegionItemLine(p).then(res=>{
                        console.log(res);
                        if(res.code==20000){
                            let itemCycle=res.data.itemCycle;
                            let seriesAry=[];
                            let legendData=[];
                            let yAxisAry=[];
                            if(yAxisType.value=='multiple'){
                                yAxisList.value.forEach((t,i)=>{
                                    yAxisAry.push({
                                        type: 'value',
                                        position:i==0?'left':'right',
                                        alignTicks:true,
                                        offset:i>1?(i-1)*60:0,
                                        axisLine: {
                                            show: true,
                                            lineStyle: {
                                                color: 'gray',
                                            }
                                        },
                                        axisLabel:{
                                            margin : 3
                                        } 
                                    })
                                })
                            }
                            else{
                                yAxisAry.push({
                                    type: 'value'
                                })
                            }
                            res.data.item.forEach(m=>{
                                legendData.push(m.itemName+'('+m.itemUnit+')');
                                //如果是多轴，获取指标项所在多轴中的索引，即第几个轴
                                let index=0;
                                if(yAxisType.value=='multiple'){
                                    yAxisList.value.forEach((t,i)=>{
                                        if(t.item.indexOf(m.id)>-1){
                                            index=i;
                                            return;
                                        }
                                    })
                                }
                                seriesAry.push({
                                    name:m.itemName+'('+m.itemUnit+')',
                                    type:showMode.value.split('_')[1],
                                    smooth: true,
                                    data:m.itemValue,
                                    yAxisIndex:index
                                })
                            })
                            if(myChart){
                                myChart.dispose();//销毁实例
                            }
                            myChart =echarts.init(document.getElementById("chart"));
                            
                            let option = {
                                tooltip: {
                                    trigger: 'axis'
                                },
                                legend: {
                                    type: 'scroll',
                                    pageIconColor:'#79bbff',
                                    // pageIconInactiveColor:'#aaa',
                                    pageTextStyle:{
                                        color:'#9CDCFE',
                                    },
                                    // top: '5',
                                    left: 'center',
                                    data:legendData,
                                },
                                dataZoom: [
                                {
                                    type: 'inside',
                                    start: 60,
                                    end: 100
                                },
                                {
                                    show: true,
                                    height: 16,
                                    bottom:20,
                                    start: 60,
                                    end: 100
                                }
                                ],
                                grid: {
                                    top: 60,
                                    bottom: 80,
                                    left:10,
                                    right:yAxisType.value=='multiple'?'15%':20,
                                    containLabel:true
                                },
                                xAxis: [
                                {
                                    type: 'category',
                                    axisTick: {
                                        alignWithLabel: true
                                    },
                                    axisLine: {
                                        onZero: false,
                                    },
                                    axisLabel: {
                                    formatter: function (params) {
                                        return parseTimeOfCycle(params,itemCycle)
                                    }
                                    },
                                    axisPointer: {
                                    label: {
                                        formatter: function (params) {
                                        return parseTimeOfCycle(params.value,itemCycle)
                                        }
                                    }
                                    },
                                    data: res.data.date
                                },
                                ],
                                yAxis: yAxisAry,
                                series: seriesAry,
                            };
                            myChart.setOption(option);
                        }
                    })
                }
                else if(showMode.value=="chart_pie"){//占比，饼图
                    let p={
                        regionCode:regionCode.value,
                        itemId:JSON.stringify(items.value)
                    }
                    getRegionItemPie(p).then(res=>{
                        console.log(res);
                        if(res.code==20000){
                            chartDate.value=res.data?.date
                            if(myChart){
                                myChart.dispose();//销毁实例
                            }
                            myChart =echarts.init(document.getElementById("chart"));
                            let option = {
                                graphic: 
                                {
                                    type: 'text',
                                    z: 100,
                                    left: 'center',
                                    top: 'middle',
                                    silent: true,
                                    invisible: !res.data?.dataList ||res.data?.dataList.length== 0?false:true, //是否可见，这里的意思是当没有数据时可见
                                    style: {
                                        fill: '#fff',
                                        //fontWeight: 'bold',
                                        text: '暂无数据',
                                        fontSize: '16px'
                                    }
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: '{a} <br/>{b} <br/> {c} ({d}%)'
                                },
                                legend: {
                                    type: 'scroll',
                                    pageIconColor:'#79bbff',
                                    // pageIconInactiveColor:'#aaa',
                                    pageTextStyle:{
                                        color:'#9CDCFE',
                                    },
                                    // top: '5',
                                    left: 'center',
                                    data:res.data?.dataList.map(t=>t.itemName+'('+t.itemUnit+')'),
                                },
                                series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: ['40%', '70%'],
                                    avoidLabelOverlap: false,
                                    emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    },
                                    label: {
                                        show: true,
                                        // fontSize: '40',
                                        fontWeight: 'bold'
                                    }
                                    },
                                    data: res.data?.dataList.map(t=>{
                                    return {
                                        value:t.itemValue,
                                        name:t.itemName+'('+t.itemUnit+')'
                                    }
                                    }),
                                }
                                ]
                            };
                            myChart.setOption(option);
                        }
                    });
                }
            }else{
                const result= await addAreaEcoSelf(param);
                console.log(result);
                if(result.code==20000){
                    //父页面的刷新
                    emit('refreshFun');
                }
                //记录用户操作日志
                addUserLog(route.name,route.fullPath,'添加区域经济',JSON.stringify(param));
            }
            
        }
        getUserFavoritesFun();
        getAllRegionListFun();
        getItemCycleList();
        return{
            //区域经济
            
            itemType,
            items,//选中的指标项
            regionCode,//地区编号
            showMode,//呈现方式
            rule,//排名规则，默认同级
            title,
            itemDesc,
            swithChangeFun,
            getUserFavoritesFun,
            userFavoritesList,
            favId,
            favList,
            getFavListFun,
            isShowItem,
            showItemFun,
            selItemFun,
            regionList,
            delItem,
            regionChangeFun,
            myCascader,
            submitFun,
            itemCycleList,
            itemCycle,
            itemCycleChangeFun,
            isCurDate,
            isShowPreview,
            sortDtInfo,
            radioChange,
            chartDate,
            yAxisType,
            yAxisList,
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.fav{
    height: 300px;
    background: rgba($themeColor, 0.1);
    border-radius: 2px;
    position: relative;
    margin-bottom: 10px;
}
.fav-left {
    position: relative;
    float: left;
    width: 120px;
    padding: 10px;
    margin-right: -1px;
    font-size: 14px;
    box-sizing: border-box;
    height: 100%;
    overflow-y:auto ;
    ul li{
        cursor: pointer;
        padding:6px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ul li:hover{
        background-color: rgba($themeColor, 0.1);
    }
    ul li.cur{
        background: rgba($themeColor, 0.3);
    }
    
}
.fav-right {
    float: left;
    width: calc(100% - 120px);
    border-left: 1px solid rgba($themeColor, 0.3);
    padding: 10px;
    height: 100%;
    overflow-y:auto ;
}
.item-tag {
    border:none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    padding: 0 9px;
    margin: 5px;
    font-size: 12px;
    line-height: 1;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
    &:hover{
       color: $specialtext-color;
    }
}
.item-tag.is-disabled,.item-tag.is-disabled:hover{
    color: #aaa;
}
.item-tag.is-sel{
    color: $specialtext-color;
}
.c-close{
    font-size: 20px;
    position: absolute;
    right: -0px;
    top: -8px;
    cursor: pointer;
    color:#d5d5d5;
    z-index: 99;
    &:hover{
        color: #aaa;
    }
}
.n-tabs{
    position: relative;
    border-bottom: 1px solid $bordercolor;
}
.n-btn {
    display: inline-block;
    line-height: 40px;
    height: 40px;
    margin: 0 10px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    bottom: -1px;
}
.active,.n-btn:hover{
    color: $specialtext-color;
    border-bottom: 2px solid $specialtext-color;
}
::v-deep .el-popper__arrow {
    &::before{
    border: none;
    background: #fff !important;
    }
  }
</style>