<template>
    <div class="clearfix">
        <div class="fav-sidenav">
            <div class="favlist-title">
                <p>我的主题</p>
            </div>
            <div class="fav-item" @click.stop="handleCommand('add',null)">
                <i class="iconfont ec-icon-circle-plus-outline fontsize14 mr-5"></i>
                <span>新建主题</span>
            </div>
            <ul>
                <li v-for="level in regionLevelList" :key="level.id" > 
                    <div class="parmenu_title">
                        <i class="iconfont ec-icon-folder-opened fontsize14 mr-5"></i>
                        <span>{{level.name}}</span>
                    </div>
                    <ul>
                    <li @click.stop class="fav-item" v-for=" item in ecoThemeList.filter(t=>t.regionLevel==level.id)" :key="item.id" :class="{'cur':item.id==selTheme.id}">
                        <el-link :underline="false" @click.prevent="selThemeFun(item.id)" >
                            <i  class="iconfont i_icon" :class="item.icon"></i>
                            <span >{{item.name}}</span>
                        </el-link>
                        <span class="num">{{item.itemNum}}</span>
                        <div  class="be-dropdown">
                            <el-dropdown  @command="handleCommand($event,item)">
                                <span class="el-dropdown-link">
                                <i class="iconfont ec-icon-more more " title='更多操作'></i>
                                </span>
                                <template #dropdown>
                                <el-dropdown-menu
                                class="popper-dropdown">
                                    <el-dropdown-item command="change">编辑信息</el-dropdown-item>
                                    <el-dropdown-item command="del">删除</el-dropdown-item>
                                </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                    </li>
                    </ul>
                </li>
            </ul>
            <!-- 新增/修改 -->
            <el-dialog v-model="changeDialogVisible" title="主题信息" width="40%">
                <div class="info-wrap">
                    <el-row class="mb-10">
                        <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="3">
                            <span >主题名称：</span>
                        </el-col>
                        <el-col :xs="16" :sm="18" :md="18" :lg="20" :xl="21">
                            <el-input v-model="editThemeName" maxlength="20" placeholder="主题名称" show-word-limit type="text"/>
                        </el-col>
                    </el-row>
                    <el-row class="mb-10">
                        <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="3" >
                            <span >图&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;标：</span>
                        </el-col>
                        <el-col :xs="16" :sm="18" :md="18" :lg="20" :xl="21">
                            <i class="iconfont  pointer" :class="editIcon" @click="isShowIconList=true"></i>
                            <el-scrollbar v-show='isShowIconList'  style='max-height:120px' tag='ul' class="icon_list">
                                <li v-for="item in iconList" @click="selIconFun(item)" :key='item'><i class="iconfont" :class="item"></i></li>
                            </el-scrollbar>
                        </el-col>
                    </el-row>
                    <el-row class="mb-10">
                        <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="3" >
                            <span>描&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;述：</span>
                        </el-col>
                        <el-col :xs="16" :sm="18" :md="18" :lg="20" :xl="21">
                            <el-input v-model="editThemeDesc" maxlength="200" placeholder="请输入描述" show-word-limit type="textarea"/>
                        </el-col>
                    </el-row>
                    <el-row class="mb-10">
                        <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="3">
                            <span>地区等级：</span>
                        </el-col>
                        <el-col :xs="16" :sm="18" :md="18" :lg="20" :xl="21">
                            <el-radio-group v-model="editThemeRegionLevel">
                                <el-radio v-for='item in regionLevelList' :key='item.id'  :label="item.id" :disabled='editThemeId>0 && editThemeItemNum>0?true:false' size="large">{{item.name}}</el-radio>
                            </el-radio-group>
                        </el-col>
                    </el-row>     
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="changeDialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="changeUserEcoThemeFun">确认</el-button>
                    </span>
                </template>
            </el-dialog>
            <!-- 删除 -->
            <el-dialog
                v-model="delDialogVisible"
                title="确认提示"
                width="30%">
                <span>{{delType==1?'确定删除这个主题吗？':'你将要删除 '+selItemIds.length+' 个自定义区域经济'}}</span>
                <template #footer>
                <span class="dialog-footer">
                    <el-button @click="delDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="delFun">确认</el-button>
                </span>
                </template>
            </el-dialog>
        </div>
        <div  class="fav-main">
            <div v-if="ecoThemeList && ecoThemeList.length>0">
                <div  class="nameBox">
                    <span  class="txt-title">{{selTheme.name}}</span>
                    <p>{{selTheme.desc}}</p>
                </div>
                <div class="t-btn">
                    <div style="text-align:right;" v-if="!isBatch">
                        <el-link :underline="false" class="btn-link" style="margin-right:20px;" @click.prevent='isBatch=!isBatch'>批量操作</el-link>
                        <el-link :underline="false" class="btn-link" :disabled='areaEcoList.length==12' @click.prevent="singleHandleCommand('add',0)">添加</el-link>
                     </div>
                     <div  v-if="isBatch">
                        <el-button type="primary" style="margin-right:30px;"  size="small" @click='backFun'>
                            <i class="iconfont ec-icon-arrow-left"></i>
                            返回
                        </el-button>
                       <el-checkbox v-model="isAllCheck" label="全选" style="margin-right:30px;" @change="allCheckFun"  />
                          
                        <el-link :underline="false" class="btn-link" style="margin-right:30px;" @click.prevent="handleCommand('delAreaEco',null)">
                            <i class="iconfont ec-icon-delete"></i> 删除
                        </el-link>
                        <el-link :underline="false" class="btn-link" style="margin-right:30px;" @click.prevent="moveFun('复制')">
                            <i class="iconfont ec-icon-document-copy"></i> 复制到
                        </el-link>
                        <el-link :underline="false" class="btn-link" @click.prevent="moveFun('移动')">
                            <i class="iconfont ec-icon-document-remove"></i> 移动到
                        </el-link>
                          <span style="float: right;display: inline-block;line-height: 30px;">已选择{{areaEcoList.filter(t=>t.isCheck).length}}个区域经济</span>
                     </div>
                </div>
                
                <div  v-if='areaEcoList.length>0'>
                    <draggable
                    :list="areaEcoList"
                    ghost-class="ghost"
                    chosen-class="chosenClass"
                    animation="300"
                    @start="onStart"
                    @end="onEnd">
                        <template #item="{ element }">
                            <div class="small-item">
                                <div>
                                    <div class="item-info">
                                        <label class="label-pos">指标：</label>
                                        <div class="tag-list">
                                            <span class="cur_tag" v-for='t in element.item' :key='t'>{{t}}</span>
                                            <i v-if='element.item.length>3' class="iconfont ec-icon-more i-tag-more"></i>
                                        </div>
                                    </div>
                                    <div class="item-info">
                                        <label class="label-pos">地区：</label>
                                        <div>
                                            <span class="cur_tag">{{element.regionName}}</span>
                                        </div>
                                    </div>
                                    <div class="item-info">
                                        <label class="label-pos">呈现：</label>
                                        <div>
                                        <span class="cur_tag">{{element.showModeInfo}}</span>
                                        </div>
                                    </div>
                                    <div v-if="isBatch" class="d-check">
                                        <el-checkbox v-model="element.isCheck" @change='checkBoxChangeFun'  />
                                    </div>
                                </div>
                                <div class="item-con">
                                    <span class="s-title">{{element.title}}</span>
                                    <div class="meta">
                                        <span>更新时间：{{parseTime(element.updateDate,'{y}-{m}-{d}')}}</span>
                                        <div  class="item-dropdown">
                                            <!-- <i class="el-icon-rank fontsize14 pointer"></i> -->
                                            <i class="iconfont ec-icon-edit-outline fontsize14 pointer i-hover mr-10" @click="singleHandleCommand('edit',element.id)"></i>
                                            <el-dropdown  @command="singleHandleCommand($event,element.id)">
                                                <span class="el-dropdown-link">
                                                <i class="iconfont ec-icon-more more " title='更多操作'></i>
                                                </span>
                                                <template #dropdown>
                                                <el-dropdown-menu
                                                class="popper-dropdown">
                                                    <el-dropdown-item command="del">删除</el-dropdown-item>
                                                    <el-dropdown-item command="copy">复制到</el-dropdown-item>
                                                    <el-dropdown-item command="move">移动到</el-dropdown-item>
                                                </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </draggable>
                </div>
                <el-empty v-if='areaEcoList.length<1' description="该主题还没有区域经济哦~" />
            </div>
            <el-empty v-if='!ecoThemeList || ecoThemeList.length<1' description="您还没有主题快去创建吧~" >
                <el-button type="primary" @click="handleCommand('add',null)">新建主题</el-button>
            </el-empty>
        </div>
        <el-dialog v-model="moveDialogVisible" :title="'你正在'+moveName+selItemIds?.length+'个区域经济'" width="40%">
            <el-scrollbar style="height:220px;">
                <ul>
                    <li v-for='item in ecoThemeList.filter(t=>t.regionLevel==selTheme.regionLevel)' :key='item.id' class='fav-item'>
                        <input type="radio" name="theme" class="c-radio" :disabled='selTheme.id==item.id || item.itemNum==12'  v-model="radioVal" :value="item.id" @change='radioFun' />
                        <span>{{item.name}}</span>
                        <span class="num">{{item.itemNum}}</span>
                    </li>
                </ul>
            </el-scrollbar>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="moveDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="setThemeOfAreaEcoFun">确认</el-button>
                </span>
            </template>
        </el-dialog>
         <el-dialog v-model="editDialogVisible" title="内容编辑" center width="50%" destroy-on-close>
            <div style="border:1px solid #DCDFE6;padding:10px;border-radius:4px;">
                <div class="item-single-info">
                    <label class="label-pos">指标：</label>
                    <div>
                        <span class="cur_tag" v-for='t in editAreaEco.item' :key='t'>{{t}}</span>
                    </div>
                </div>
                <div class="item-single-info">
                    <label class="label-pos">地区：</label>
                    <div>
                        <span class="cur_tag" style="background:#529b2e;">{{editAreaEco.regionName}}</span>
                    </div>
                </div>
                <div class="item-single-info">
                    <label class="label-pos">呈现：</label>
                    <div>
                        <span class="cur_tag" style="background:#f89898;">{{editAreaEco.showModeInfo}}</span>
                    </div>
                </div>
                <!-- <div class="item-single-info">
                    <label class="label-pos">时间：</label>
                    <div>
                        <el-checkbox checked='true' label="应用数据最新日期" size="large" />
                        <span >{{editAreaEco?.date?editAreaEco?.date:"应用数据最新日期"}}</span>
                    </div>
                </div> -->
                <div class="item-single-info">
                    <label class="label-pos">标题：</label>
                    <div>
                        <el-input v-model="editAreaEco.title" maxlength="20" placeholder="输入标题" show-word-limit type="text" />
                    </div>
                </div>
                <br>
                <div class="item-single-info">
                    <label class="label-pos">描述：</label>
                    <div>
                        <el-input v-model="editAreaEco.desc" maxlength="200" placeholder="请输入描述" show-word-limit type="textarea" />
                    </div>
                </div>
                <div style="text-align:right;">
                    <el-button class="btn-defalut" @click="changeAreaEcoFun" >确认</el-button>
                </div>
                
            </div>
        </el-dialog>
        <el-drawer v-model="addDialogVisible" title="添加区域经济" direction="rtl" size="40%" custom-class="cus-drawer" destroy-on-close>
            <add-area-eco-self :themeId='selTheme.id' :regionLevel='selTheme.regionLevel' @refreshFun="refreshFun"></add-area-eco-self>
        </el-drawer>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import {getUserEcoTheme,addUserEcoThemeSelf,changeUserEcoThemeSelf,delUserEcoThemeSelf,getItemOfThemeSelf,delAreaEcoSelf,setThemeOfAreaEcoSelf,changeAreaEcoSelf,setAreaEcoSortOfThemeSelf,addUserLog} from '@/http/basicsApi.js'
//import { useRouter } from 'vue-router';
import { ElMessage  } from 'element-plus'
import AddAreaEcoSelf from '@/components/AddAreaEcoSelf.vue'
import {parseTime,iconAry} from '@/utils'
import draggable from "vuedraggable";
import {useStore} from 'vuex'
import { computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
export default {
    components:{
        AddAreaEcoSelf,
        draggable
    },
    setup() {
        const route =useRoute()
        let currentUser=ref(JSON.parse(localStorage.getItem('currentUser')));//当前用户信息
        let regionLevelList=computed(()=>useStore().state.regionLevelList);//地区等级列表
        let selTheme=ref(null);//选中的主题信息
     
        let editThemeId=ref(0);//修改的主题ID,
        let editThemeName=ref('');//修改的主题名称
        let editThemeRegionLevel=ref(-1);//修改主题的地区等级
        let editThemeDesc=ref('');//修改主题的描述
        let isShowIconList=ref(false)//显示图标列表
        let editIcon=ref('ec-icon-fill')//选中的图标
        let iconList=ref(iconAry);
        let editThemeItemNum=ref(0);//修改主题的区域经济部件的个数
        let delThemeId=ref(0);//要删除的主题ID
        let changeDialogVisible=ref(false);//添加修改主题的对话框是否显示
        let delDialogVisible=ref(false);//删除提示框是否显示
        let areaEcoList=ref([]);//区域经济列表
        let delType=ref(1);//删除类型，1：删除主题，2：删除主题中的区域经济
        let isBatch=ref(false);//是否批量操作
        let isAllCheck=ref(false);//是否全选
        let moveDialogVisible=ref(false);//移动或复制对话框
        let moveName=ref('');//移动或复制
        let radioVal=ref(0);//单选选中值
        let editDialogVisible=ref(false);//是否编辑区域经济
        let editAreaEco=ref(null);//需要编辑的区域经济部件
        let addDialogVisible=ref(false);//是否添加区域经济


        //获取用户区域经济主题
        let ecoThemeList=ref([]);//主题列表
        const getUserEcoThemeFun=async()=>{
            let result=await getUserEcoTheme(currentUser.value?.userId,2);
            console.log(result);
            if(result && result.code==20000){
                ecoThemeList.value=result.data;
                if(result.data.length>0){
                    if(!selTheme.value){
                        //默认第一个主题
                        selThemeFun(result.data[0].id);
                    }
                }
            }   
        }
        //选择主题
        const selThemeFun=(id)=>{
            selTheme.value=ecoThemeList.value.find(t=>t.id==id);
            isBatch.value=false;
            isAllCheck.value=false;
            //获取相应主题的区域经济
            getItemOfThemeFun();
        }
        //功能操作
        const handleCommand=(command,item)=>{
            //console.log(command);
            //console.log(item);
            if(command=='add' ){//添加
                changeDialogVisible.value=true;
                isShowIconList.value=false;
                editThemeId.value=0;
                editThemeName.value='';//修改的主题名称
                editThemeRegionLevel.value=-1;//修改主题的地区等级
                editThemeDesc.value='';//修改主题的描述
                editThemeItemNum.value=0;
                editIcon.value='ec-icon-fill';
            }
            if( command=='change'){//修改
                changeDialogVisible.value=true;
                isShowIconList.value=false;
                editThemeId.value=item.id;
                editThemeName.value=item.name;//修改的主题名称
                editThemeRegionLevel.value=item.regionLevel;//修改主题的地区等级
                editThemeDesc.value=item.desc;//修改主题的描述
                editThemeItemNum.value=item.itemNum;
                editIcon.value=item.icon;
            }
            else if(command=='del'){//删除
                delDialogVisible.value=true;
                delType.value=1;
                delThemeId.value=item.id;
            }
            
            else if(command=='delAreaEco'){//删除多个自定义区域经济
                selItemIds.value=[];
                let fitlerAry=areaEcoList.value.filter(t=>t.isCheck==true);
                if(fitlerAry.length>0){
                    selItemIds.value=fitlerAry.map(t=>t.id)
                    delDialogVisible.value=true;
                    delType.value=2;
                }
                else{
                     ElMessage.warning({
                        message: '请选择要删除的区域经济' 
                    }); 
                }
            }
        }
        const changeUserEcoThemeFun= async()=>{
         
            if(!editThemeName.value){
                ElMessage.error({
                    message: '主题名称为空！', 
                });   
                return; 
            }
            if(editThemeRegionLevel.value<0){
                ElMessage.error({
                    message: '无地区等级！', 
                });   
                return; 
            }
            let params={
                id:editThemeId.value,
                name:editThemeName.value,//修改的主题名称
                regionLevel:editThemeRegionLevel.value,//修改主题的地区等级
                icon:editIcon.value,
                desc:editThemeDesc.value,//修改主题的描述
            }
            //console.log(params);
            if(editThemeId.value==0){
                //添加
                const result=await addUserEcoThemeSelf(params);
                //console.log(result);
                if(result.code==20000){
                    changeDialogVisible.value=false;
                    getUserEcoThemeFun();
                }
                else{
                    ElMessage.error({
                        message: result.message, 
                    }); 
                }
            }
            else{
                //修改
                const result=await changeUserEcoThemeSelf(params);
                //console.log(result);
                if(result.code==20000){
                    changeDialogVisible.value=false;
                    let index = ecoThemeList.value.map((item) => item.id).indexOf(editThemeId.value); //索引
                    let itemNum= ecoThemeList.value[index].itemNum;
                    let changeInfo={
                        id: editThemeId.value,
                        itemNum: itemNum,
                        name: editThemeName.value,
                        desc:editThemeDesc.value,
                        icon:editIcon.value,
                        regionLevel:editThemeRegionLevel.value
                    }
                    ecoThemeList.value.splice(index,1,changeInfo);
                    if(editThemeId.value==selTheme.value.id){
                        selTheme.value=changeInfo;
                    }
                }
                else{
                    ElMessage.error({
                        message: result.message, 
                    }); 
                }
            }
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,editThemeId.value==0?'添加主题':'修改主题',JSON.stringify(params));
        }
        //删除主题
        const delFun=async()=>{
            if(delType.value==1){
                let param={
                    ids:delThemeId.value.toString()
                };
                const result=await delUserEcoThemeSelf(param);
                delDialogVisible.value=false;
                //console.log(result.message);
                if(result.code==20000){
                    let index = ecoThemeList.value.map((item) => item.id).indexOf(delThemeId.value); //索引
                    ecoThemeList.value.splice(index,1);
                    //删除项为选择项，删除后默认第一主题
                    if(delThemeId.value==selTheme.value?.id && ecoThemeList.value.length>0){
                        selThemeFun(ecoThemeList.value[0]['id']);
                    }
                    else if(ecoThemeList.value.length==0){
                        selTheme.value=null;
                    }
                    ElMessage.success({
                        message: result.message, 
                    });   
                }
                else{
                    ElMessage.error({
                        message: result.message, 
                    });  
                }
                //记录用户操作日志
                addUserLog(route.name,route.fullPath,'删除主题',JSON.stringify(param));
            }
            else{
                delAreaEcoFun();
            }
            
        }
        //为主题选择图标
        
        const selIconFun=(code)=>{
            isShowIconList.value=false;
            editIcon.value=code;
        }
        //获取相应主题的区域经济
        const getItemOfThemeFun= async()=>{
            let result=await getItemOfThemeSelf(selTheme.value.id);
            //console.log(result);
            if(result.code==20000){
                areaEcoList.value=result.data;
                areaEcoList.value.forEach(item=>{
                   
                    if(item.showMode=='chart_line'){
                        item.showModeInfo='趋势(折线图)'
                    }
                    else if(item.showMode=='chart_bar'){
                        item.showModeInfo='趋势(柱状图)'
                    }
                    else if(item.showMode=='chart_pie'){
                        item.showModeInfo='占比(饼图)'
                    }
                    else if(item.showMode=='list_sort' && item.rule.sort=='grade'){
                        item.showModeInfo='排名(同级地区)'
                    }
                    else if(item.showMode=='list_sort' && item.rule.sort=='province'){
                        item.showModeInfo='排名(本省)'
                    }
                    else if(item.showMode=='list_sort' && item.rule.sort=='city'){
                        item.showModeInfo='排名(本市)'
                    }
                    item.isCheck=false;
                })
                let index=ecoThemeList.value.map(t=>t.id).indexOf(selTheme.value.id);
                ecoThemeList.value[index].itemNum=areaEcoList.value.length;
            }
        }
        //返回
        const backFun=()=>{
            isBatch.value=!isBatch.value;
            isAllCheck.value=false;
            areaEcoList.value.forEach(item=>{
                item.isCheck=false;
            })
        }
        //全选
        const allCheckFun=(val)=>{
            areaEcoList.value.forEach(item=>{
                item.isCheck=val
            })

        }
        ///单个区域经济的选择和取消选择
        const checkBoxChangeFun=(val)=>{
            
            if(!val){
                isAllCheck.value=false;
            }
            else if(areaEcoList.value.filter(t=>t.isCheck).length==areaEcoList.value.length){
                isAllCheck.value=true;
            }
        }
        let selItemIds=ref([]);//选中区域经济ID数组
        //单个区域经济的操作（删除、复制、移动）
        const singleHandleCommand=(command,itemId)=>{
            selItemIds.value=[];//清空
            selItemIds.value.push(itemId);
            console.log(selItemIds.value);
            if(command=='add'){//添加区域经济
                addDialogVisible.value=true;
            }
            else if(command=='edit'){//修改区域经济
                editDialogVisible.value=true;
                console.log(areaEcoList.value);
                editAreaEco.value=areaEcoList.value.find(t=>t.id==itemId)
            }
            else if(command=='del'){//删除
                delDialogVisible.value=true;
                delType.value=2;
            }
            else if(command=='copy'){//复制到
                moveName.value='复制';
                moveDialogVisible.value=true; 
            }
            else if(command=='move'){//移动到
                moveName.value='移动';
                moveDialogVisible.value=true;
            }
        }
        ///删除区域经济
        const delAreaEcoFun= async()=>{
            let param={
                ids:selItemIds.value.join(',')
            };
            //console.log(param);
            const result=await delAreaEcoSelf(param);
            if(result.code==20000){
                areaEcoList.value=areaEcoList.value.filter(t=> (','+selItemIds.value.join(',')+',').indexOf(','+t.id+',')<0);
                isAllCheck.value=false;
                let index=ecoThemeList.value.map(t=>t.id).indexOf(selTheme.value.id);
                ecoThemeList.value[index].itemNum=areaEcoList.value.length;
                selItemIds.value=[];//清空
                ElMessage.success({
                    message: result.message, 
                }); 
            }
            else{
                ElMessage.error({
                    message: result.message, 
                });
            }
            delDialogVisible.value=false;
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'删除区域经济',JSON.stringify(param));
        }
        //显示移动或复制对话框
        const moveFun=(name)=>{
            selItemIds.value=[];//清空
            let filterAry=areaEcoList.value.filter(t=>t.isCheck);
            if(filterAry.length<1){
                ElMessage.error({
                    message: "请选择要"+name+"的区域经济", 
                });
            }
            else{
                moveName.value=name;
                selItemIds.value=filterAry.map(t=>t.id);
                moveDialogVisible.value=true;
            }
            
        }
        //设置区域经济所在的主题
        const setThemeOfAreaEcoFun=async()=>{
            let param={
                ids:selItemIds.value.join(','),
                type:moveName.value=="复制"?1:2,//1:复制，2：移动
                targetThemeId:radioVal.value
            }
            //console.log(param);
            const result= await setThemeOfAreaEcoSelf(param);
            //console.log(result);
            if(result.code==20000){
                //移动将进行删除
                if(moveName.value=="移动"){
                    areaEcoList.value=areaEcoList.value.filter(t=>(','+selItemIds.value.join(',')+',').indexOf(','+t.id+',')<0);
                }
                areaEcoList.value.forEach(t=>t.isCheck=false);
                selItemIds.value=[];
                radioVal.value=0;
                isAllCheck.value=false;
                getUserEcoThemeFun();
            }
            else{
                //areaEcoList.value.forEach(t=>t.isCheck=false);
                //isAllCheck.value=false;
                radioVal.value=0;
            }
            //关闭对话框
            moveDialogVisible.value=false;
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,moveName.value,JSON.stringify(param));
        }
        //添加区域经济部件后刷新区域经济列表
        const refreshFun=()=>{
            //console.log('refresh');
            //关闭对话框
            addDialogVisible.value=false;
            //重新加载区域经济列表
            getItemOfThemeFun();
            
        }
        //修改区域经济部件
        const changeAreaEcoFun=async()=>{
            let params={
                id:editAreaEco.value.id,
                title:editAreaEco.value.title,
                desc:editAreaEco.value.desc
            };
            const result=await changeAreaEcoSelf(params);
            if(result.code==20000){
                editDialogVisible.value=false;
                ElMessage.success({
                    message: result.message, 
                }); 
            }
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'修改区域经济',JSON.stringify(params));
        }

        //拖拽开始的事件
        const onStart = () => {
            console.log("开始拖拽");
        };

        //拖拽结束的事件
        const onEnd = async() => {
            console.log("结束拖拽");
            let list=areaEcoList.value.map((t,index)=>{
                return {
                    id:t.id,
                    sort:index
                }
            })
            let params={
                Ids:JSON.stringify(list)
            }
            await setAreaEcoSortOfThemeSelf(params);
            // if(result.code==20000){
            //     ElMessage.success({
            //         message: '拖动成功', 
            //     }); 
            // }
        };
        //获取主题的区域经济
        getUserEcoThemeFun();
        //记录用户操作日志
        addUserLog(route.name,route.fullPath,'加载','');
        return {
            currentUser,
            regionLevelList,
            ecoThemeList,
            selThemeFun,
            handleCommand,
            selTheme,//选中的主题信息
            editThemeId,//修改的主题ID,
            editThemeName,//修改的主题名称
            editThemeRegionLevel,//修改主题的地区等级
            editThemeDesc,//修改主题的描述
            editThemeItemNum,//修改主题的区域经济部件的个数
             editIcon,
            iconList,
            isShowIconList,
            selIconFun,
            delThemeId,//要删除的主题ID
            changeDialogVisible,
            delDialogVisible,
            delType,
            changeUserEcoThemeFun,
            delFun,
            areaEcoList,
            isBatch,
            backFun,
            isAllCheck,
            allCheckFun,
            checkBoxChangeFun,
            moveDialogVisible,
            moveName,
            radioVal,
            selItemIds,
            singleHandleCommand,
            moveFun,
            setThemeOfAreaEcoFun,
            editDialogVisible,
            editAreaEco,
            changeAreaEcoFun,
            addDialogVisible,
            refreshFun,
            parseTime,
            onStart,
            onEnd
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.favlist-title{
    padding-left: 20px;
    font-size: 16px;
    color: #99a2aa;
}
.fav-sidenav {
    position: relative;
    float: left;
    width: 180px;
    margin-right: -1px;
    font-size: 14px;
    box-sizing: border-box;
    
}
.fav-main {
    float: left;
    width: calc(100% - 180px);
    min-height: 600px;
    border-left: 1px solid $bordercolor;
    padding:0 20px 20px 20px;
}
.fav-item {
    position: relative;
    padding-left: 40px;
    transition: background-color .3s ease;
    white-space: nowrap;
    overflow: hidden;
    line-height: 44px;
    cursor: pointer;
    a{
        width: 100%;
        justify-content:left;
    }
}
.fav-sidenav .fav-item.cur,.fav-sidenav .fav-item.cur:hover {
    background: rgba($themeColor, 0.1); 
}
.fav-sidenav .fav-item:hover {
    background-color: rgba($themeColor, 0.05);
    a{
    color:inherit;
    }
    .num{
         color: #fff;
        display: none;
    }
    .be-dropdown{
        display: inline-block;
    }
}
.fav-item .num {
    display: inline-block;
    width: 32px;
    font-size: 12px;
    color: #99a2aa;
    vertical-align: middle;
    text-align: center;
    font-family: Arial;
    position:absolute;
    right: 0;
}
.more{
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    cursor: pointer;
}
.more:hover,.i-hover:hover{
    color: $specialtext-color;
}
.be-dropdown{
    width: 32px;
    position: absolute;
    right: 1px;
    text-align: center;
    z-index: 99999;
    
    display: none;
}
.small-item {
    padding: 0;
    margin: 10px;
    border-radius: 4px;
    border: 1px solid $bordercolor;
    box-sizing: border-box;
    display: block;
    float: left;
    width: calc(33.3% - 20px);
    position: relative;
}
.small-item:hover {
  cursor: move;
}
.item-info{
    padding-left: 40px;
    position: relative;
    .cur_tag{
        margin: 6px;
        padding: 3px 6px;
        display: inline-block;
        border-radius: 2px;
        border: 1px solid $bordercolor;
        background-color: rgba($themeColor, 0.1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(33% - 12px);
    }
    .label-pos{
        position:absolute;
        left: 6px;
        top:10px;
    }
}

.tag-list{
    height: 39px;
    overflow: hidden;
    position: relative;
}

.i-tag-more{
    position: absolute;
    right: 0;
    bottom: 0px;
    background-image: linear-gradient(to right, transparent, rgba($themeColor,0.6) 120%);
    height: 28px;
    width: 30px;
    line-height: 30px;
    bottom: 6px;
    text-align: right;
    padding-right: 3px;
}
.small-item .item-con {
    display: block;
    line-height: 20px;
    margin-top:6px;
    padding:0 6px;
    overflow: hidden;
    background-color: rgba($themeColor, 0.1);
    border-top: 1px dashed rgba($themeColor, 0.3);
}
.item-con  .s-title{
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    overflow: hidden;.el-link.el-link--default.is-disabled {
    color: #aaa;
}
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}
.small-item .meta {
    color: #999;
    white-space: nowrap;
    font-size: 0;
    height: 20px;
    line-height: 20px;
    position: relative;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    ::v-deep .el-dropdown{
        color: inherit;
    }
}

.item-dropdown{
    position: absolute;
    right: -0px;
    top: 0px;
    text-align: center;
    z-index: 9;
}

.btn-link{
  font-size:14px;
  vertical-align: text-bottom;
  &:hover{
      color: $specialtext-color;
  }
}
.btn-link.is-disabled {
    color: #aaa;
    cursor: not-allowed;
}
.d-check{
    display: inline-block;
    position:absolute;
    top:6px;
    right: 10px;
    z-index: 9;
    ::v-deep .el-checkbox .el-checkbox__inner{
        width: 20px;
        height:20px;
        background-color: transparent;
        border:2px solid $themeColor;
        &::after{
            height: 10px;
            width: 6px;
            top: 0px;
            border: 2px solid $themeColor;
            border-left: 0;
            border-top: 0;
        }
    }
}
.c-radio{
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    margin-right: 5px;
}
.item-single-info{
    padding-left: 60px;
    position: relative;
    margin-bottom: 10px;
    .cur_tag{
        padding: 3px 6px;
        display: inline-block;
        background: #3178c6;
        color: #fff;
        margin-right: 5px;
        border-radius: 4px;
        margin-bottom: 5px;
    }
    .label-pos{
        position:absolute;
        left: 6px;
        top:3px;
    }
}
.btn-defalut{
    color:#fff;
    background-color: #409eff;
}
.btn-defalut:hover, .btn-defalut:focus {
    color:#fff;;
    border-color: #3375b9;
    background-color: #3375b9;
}

// .small-item:nth-child(4n) {
//     margin-right: 0;
// }
// .popper-dropdown {
//   min-width: 120px;
//   position: absolute !important;
//   top: 32px !important;
//   right: 0px !important;
// }

.ghost {
  border: 1px solid $bordercolor;
}
.chosenClass {
  background-color: rgba(255, 255, 255, 0.5);
}
.icon_list{
    li{
        float: left;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        &:hover{
            color: rgba(0,47,167);
        }
    }
    
}
::v-deep .fav-item .el-link--inner{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    display:inline-block;
    width:calc(100% - 40px);
}
</style>